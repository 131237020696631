<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <form autocomplete="off" method="post">
      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'placeToken'"
            :value="enquiryReservationData.placeToken"
            :options="placeTokenOptions"
            v-on:changeValue="
              enquiryReservationData.placeToken = $event;
              placeTokenChanged();
            "
            :title="$t('places.select')"
            :imgName="'places.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="'placePartToken'"
            :value="enquiryReservationData.placePartToken"
            :options="placePartTokenOptions"
            v-on:changeValue="enquiryReservationData.placePartToken = $event"
            :title="$t('placesParts.select')"
            :imgName="'PlacesParts.svg'"
          />

          <DatePicker
            :className="'col-md-12 col-lg-4'"
            :id="'dateFrom'"
            :value="dateFrom"
            :title="$t('reservations.date')"
            v-on:changeValue="dateFrom = $event"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6 col-lg-4'"
            :id="'timeFrom'"
            :value="timeFrom"
            :title="$t('timeFrom')"
            v-on:changeValue="timeFrom = $event"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6 col-lg-4'"
            :id="'timeTo'"
            :value="timeTo"
            :title="$t('timeTo')"
            v-on:changeValue="timeTo = $event"
            :language="language"
          />
        </div>
      </div>

      <!-- <div class="my-card">
        <div class="row">
          <DatePicker
            :className="'col-md-6'"
            :id="'dateFrom'"
            :value="dateFrom"
            :title="$t('dateFrom')"
            v-on:changeValue="dateFrom = $event"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :id="'timeFrom'"
            :value="timeFrom"
            :title="$t('timeFrom')"
            v-on:changeValue="timeFrom = $event"
            :language="language"
          />

          <DatePicker
            :className="'col-md-6'"
            :id="'dateTo'"
            :value="dateTo"
            :title="$t('dateTo')"
            v-on:changeValue="dateTo = $event"
            :language="language"
          />
          <TimePicker
            :className="'col-md-6'"
            :id="'timeTo'"
            :value="timeTo"
            :title="$t('timeTo')"
            v-on:changeValue="timeTo = $event"
            :language="language"
          />
        </div>
      </div> -->

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="getAppointmentsAvailableForReservation()"
          :disabled="searchIsDisabled"
        >
          {{ $t("search") }}
        </button>
      </div>

      <div class="my-card">
        <div class="row">
          <CustomSelectBox
            v-if="!isClient"
            :className="'col-md-12'"
            :id="'clientToken'"
            :value="enquiryReservationData.clientToken"
            :options="clientTokenOptions"
            v-on:changeValue="enquiryReservationData.clientToken = $event"
            :title="$t('selectClient')"
            :imgName="'clients.svg'"
          />

          <!-- <TextArea
            :className="'col-md-6'"
            :id="'enquiryReservationDescriptionAr'"
            :value="enquiryReservationData.enquiryReservationDescriptionAr"
            v-on:changeValue="
              enquiryReservationData.enquiryReservationDescriptionAr = $event
            "
            :title="$t('reservations.descriptionAr')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'enquiryReservationDescriptionEn'"
            :value="enquiryReservationData.enquiryReservationDescriptionEn"
            v-on:changeValue="
              enquiryReservationData.enquiryReservationDescriptionEn = $event
            "
            :title="$t('reservations.descriptionEn')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'enquiryReservationDescriptionUnd'"
            :value="enquiryReservationData.enquiryReservationDescriptionUnd"
            v-on:changeValue="
              enquiryReservationData.enquiryReservationDescriptionUnd = $event
            "
            :title="$t('reservations.descriptionUnd')"
            :imgName="'description.svg'"
          />
          <TextArea
            :className="'col-md-6'"
            :id="'enquiryReservationNotes'"
            :value="enquiryReservationData.enquiryReservationNotes"
            v-on:changeValue="
              enquiryReservationData.enquiryReservationNotes = $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          /> -->
        </div>

        <ExceptionWithImg
          v-if="exceptionMsgAvailableAppointments"
          :msg="exceptionMsgAvailableAppointments"
          :showImageStatus="false"
        />

        <div
          v-if="
            availableAppointments !== undefined &&
            availableAppointments.length !== 0
          "
        >
          <div>
            <input
              type="checkbox"
              id="selectAll"
              @click="selectAll"
              v-model="allSelected"
              class="checkbox"
            />
            <label class="mt-1 ml-2" for="selectAll">{{
              $t("selectAll")
            }}</label>
          </div>

          <div
            class="row"
            v-for="(item, index) in availableAppointments"
            :key="index"
          >
            <lable v-if="item.statusReservationApproved == true">
              {{ item.appointmentNameCurrent }}
            </lable>

            <div v-else class="col-lg-12">
              <input
                type="checkbox"
                :id="`item${index}`"
                :value="item"
                v-model="
                  enquiryReservationData.enquiryReservationsSchedulesData
                "
                @click="select"
                class="checkbox"
              />
              <label class="mt-1 ml-2" :for="`item${index}`">
                {{ item.appointmentNameCurrent }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="form-footer">
        <button
          v-if="
            availableAppointments !== undefined &&
            availableAppointments.length !== 0
          "
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addEnquiryReservation()"
          :disabled="addIsDisabled"
        >
          {{ submitName }}
        </button>
        <router-link
          to="/reservations/enquiry-reservations"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import { PLACE_SCHEDULE_TYPE } from "@/utils/constantLists";
import axios from "axios";
import { setDataMultiLang, getObjectOfDateTime } from "@/utils/functions";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
// import TextArea from "@/components/general/TextArea.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import Place from "@/models/places/places/Place";
import PlacePart from "@/models/places/placesParts/PlacePart";
import Client from "@/models/clients/Client";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EnquiryReservationForm",
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    CustomSelectBox,
    // TextArea,
    DatePicker,
    TimePicker,
  },
  data() {
    return {
      isClient: false,
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      placeData: new Place(),
      placeTokenOptions: [],
      placePartData: new PlacePart(),
      placePartTokenOptions: [],
      clientData: new Client(),
      clientTokenOptions: [],
      exceptionMsgAvailableAppointments: this.$t(
        "enquiryReservations.noEnquiryReservationsSchedulesData"
      ),
      availableAppointments: [],
      allSelected: false,
      dateFrom: getObjectOfDateTime(new Date()).date,
      timeFrom: "12:00:00 am",
      dateTo: "",
      timeTo: "11:59:59 pm",
    };
  },
  props: ["enquiryReservationData", "submitName"],
  methods: {
    async setUserType() {
      if (this.$store.getters.userData.clientPersonalData) {
        this.isClient = true;
      }
    },
    selectAll() {
      this.allSelected = !this.allSelected;
      this.enquiryReservationData.enquiryReservationsSchedulesData = [];

      if (this.allSelected) {
        for (let item in this.availableAppointments) {
          this.enquiryReservationData.enquiryReservationsSchedulesData.push(
            this.availableAppointments[item]
          );
        }
      }
    },
    select() {
      setTimeout(() => {
        if (
          this.availableAppointments.length ==
          this.enquiryReservationData.enquiryReservationsSchedulesData.length
        )
          this.allSelected = true;
        else this.allSelected = false;
      }, 500);
    },
    async addEnquiryReservation() {
      this.$emit("addEnquiryReservation");
    },
    async getDialogOfPlaces() {
      this.isLoading = true;
      this.placeTokenOptions = [];
      this.placeTokenOptions.push({
        value: "",
        text: this.$t("places.select"),
      });
      try {
        let response = await this.placeData.getDialogOfPlaces(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;

        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.placeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    placeTokenChanged() {
      this.placePartData.placeToken = this.enquiryReservationData.placeToken;
      this.enquiryReservationData.placePartToken = "";
      this.getDialogOfPlaceParts();
    },
    async getDialogOfPlaceParts() {
      this.isLoading = true;
      this.placePartTokenOptions = [];
      this.placePartTokenOptions.push({
        value: "",
        text: this.$t("placesParts.select"),
      });
      try {
        // this.placePartData.placeToken = this.placePartData.placeToken ? this.placePartData.placeToken : "-1";
        if (this.placePartData.placeToken) {
          const response = await this.placePartData.getDialogOfPlaceParts(
            this.language,
            this.userAuthorizeToken
          );
          const itemsData = response.data.itemsData;
          if (response.data.status == STATUS.SUCCESS) {
            for (let item in itemsData) {
              this.placePartTokenOptions.push({
                value: itemsData[item]["itemToken"],
                text:
                  this.language == "en"
                    ? itemsData[item]["itemNameEn"]
                    : itemsData[item]["itemNameAr"],
              });
            }
          } else if (response.data.status == STATUS.INVALID_TOKEN) {
            this.$store.dispatch("logoutUser");
            this.$router.push("/").catch(() => {});
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.placePartTokenOptions = null;
      }
      this.isLoading = false;
    },
    async getDialogOfClients() {
      this.isLoading = true;
      this.clientTokenOptions = [];
      this.clientTokenOptions.push({
        value: "",
        text: this.$t("selectClient"),
      });
      try {
        let response = await this.clientData.getDialogOfClients(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;

        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.clientTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getAppointmentsAvailableForReservation() {
      this.isLoading = true;
      var data = {
        language: this.language,
        userAuthorizeToken: this.userAuthorizeToken,
        placeToken: this.enquiryReservationData.placeToken,
        placePartToken: this.enquiryReservationData.placePartToken,
        enquiryReservationsSchedulesData: [
          {
            // dateTimeFrom: `${this.dateFrom} ${this.timeFrom}`,
            // dateTimeTo: `${this.dateTo} ${this.timeTo}`,
            dateTimeFrom: `${this.dateFrom} ${this.timeFrom}`,
            dateTimeTo: `${this.dateFrom} ${this.timeTo}`,
            placeScheduleTypeToken: PLACE_SCHEDULE_TYPE.fixed,
          },
        ],
      };
      try {
        let response = await axios.post(
          `/EnquiryReservations/GetAppointmentsAvailableForReservation`,
          data
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.availableAppointments = response.data.availableAppointments;
          this.exceptionMsgAvailableAppointments = null;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.availableAppointments = [];
          this.$store.dispatch("updateUserData", null);
          this.$router.push("/").catch(() => {});
        } else {
          this.availableAppointments = [];
          this.exceptionMsgAvailableAppointments = response.data.msg;
        }
        this.isLoading = false;
      } catch (error) {
        this.availableAppointments = [];
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    setDataMultiLang(lang, dataAr, dataEn) {
      return setDataMultiLang(lang, dataAr, dataEn);
    },
  },
  watch: {},
  computed: {
    searchIsDisabled() {
      return (
        // this.enquiryReservationData.placeToken == "" ||
        // this.dateFrom == "" ||
        // this.timeFrom == "" ||
        // this.dateTo == "" ||
        // this.timeTo == ""
        this.enquiryReservationData.placeToken == "" ||
        this.dateFrom == "" ||
        this.timeFrom == "" ||
        this.timeTo == ""
      );
    },
    addIsDisabled() {
      return (
        this.enquiryReservationData.clientToken === "" ||
        this.enquiryReservationData.enquiryReservationsSchedulesData ===
          undefined ||
        this.enquiryReservationData.enquiryReservationsSchedulesData.length ===
          0
      );
    },
  },
  async created() {
    this.setUserType();
    this.getDialogOfPlaces();
    this.placeTokenChanged(); // filter not working right
    this.getDialogOfPlaceParts();
    this.getDialogOfClients();
  },
};
</script>
